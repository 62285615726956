var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction-memo childpool-wrap"},[_c('CCard',[_c('CCardBody',[_c('CRow',{staticClass:"p-1"},[_c('CCol',{staticClass:"p-1 pl-3 pr-3",attrs:{"col":"12","xl":"6"}},[_c('CInput',{attrs:{"label":_vm.$t('TRANSACTION_MEMO_PAGE_LABEL_PARTNER_ID'),"horizontal":"","value":_vm.partnerID,"disabled":""}})],1),_c('CCol',{staticClass:"p-1 pl-3 pr-3",attrs:{"col":"12","xl":"6"}},[_c('CInput',{attrs:{"label":_vm.$t('TRANSACTION_MEMO_PAGE_LABEL_PARTNER_NAME'),"horizontal":"","value":_vm.partnerName,"disabled":""}})],1)],1)],1)],1),_c('CCard',[_c('CCardBody',[_c('div',{staticClass:"font-weight-bold mb-1"},[_vm._v(_vm._s(_vm.$t("TRANSACTION_MEMO_PAGE_TITLE_TRANSACTION_MEMO")))]),_c('CRow',[_c('CCol',{attrs:{"col":"12","sm":"7","xl":"6"}},_vm._l((_vm.memos),function(memo){return _c('CRow',{key:memo.id,staticClass:"custom-input",attrs:{"alignVertical":"center"}},[_c('CCol',{staticClass:"text-uppercase",attrs:{"sm":"3"}},[_vm._v(_vm._s(memo.platform))]),_c('CCol',{attrs:{"sm":"9"}},[_c('ValidationProvider',{attrs:{"name":memo.platform,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(memo.memo),expression:"memo.memo"}],staticClass:"input-field",attrs:{"name":memo.platform},domProps:{"value":(memo.memo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(memo, "memo", $event.target.value)}}}),_c('p',{staticClass:"err-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),1),_c('CCol',{staticClass:"mt-2 text-right",attrs:{"col":"12"}},[_c('CButton',{staticClass:"pl-4 pr-4",attrs:{"color":"primary"},on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$t("TRANSACTION_MEMO_PAGE_BUTTON_SAVE")))])],1)],1)],1)],1),_c('CCard',[_c('CCardBody',[_c('div',{staticClass:"font-weight-bold m-1"},[_vm._v(_vm._s(_vm.$t("TRANSACTION_MEMO_PAGE_TITLE_HISTORY")))]),_c('CDataTable',{staticClass:"mb-0",attrs:{"outlined":"","striped":"","hover":"","items":_vm.transactions,"fields":_vm.tableHeaders,"sort-desc":"true","head-color":"light","no-sorting":""},scopedSlots:_vm._u([{key:"order",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1))])}},{key:"platform",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.platform))])}},{key:"memo",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.memo))])}},{key:"updateAt",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("moment")(item.updated_at,"YYYY-MM-DD HH:mm")))])}},{key:"updateBy",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.updated_by_user_name))])}}])}),_c('div',[(_vm.pages>1)?_c('CPagination',{attrs:{"activePage":_vm.activePage,"pages":_vm.pages,"align":"end"},on:{"update:activePage":[function($event){_vm.activePage=$event},_vm.pushPage],"update:active-page":function($event){_vm.activePage=$event}}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }